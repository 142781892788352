/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
module.exports = {
  localeDetection: false,
  defaultLocale: 'en',
  locales: ['es', 'en'],
  pages: {
    '*': ['header', 'common', 'footer'],
    '/': ['home', 'properties'],
    '/faq': ['faq', 'home'],
    '/about': ['about'],
    '/privacy': ['privacy'],
    '/legal': ['legal'],
    '/cookies': ['cookies'],
    '/properties': ['properties'],
    'rgx:/user/*': ['auth'],
    'rgx:/wizard/*': ['wizard'],
    '/agency': ['agency', 'home'],
    '/purchase': ['home', 'purchase'],
    '/new-purchase': ['new_purchase'],
    'rgx:/properties/*': ['property_detail', 'home', 'reviews', 'properties'],
    'rgx:/admin/*': ['admin', 'property_detail'],
    '/owners': ['owners'],
    '/account': ['account', 'my_account', 'my_house', 'how_it_works'],
    '/how-it-works': ['how_it_works', 'home'],
    '/reviews': ['reviews', 'home'],
  },
  loadLocaleFrom: (lang, ns) => require(`./src/i18n/locales/${lang}/${ns}.json`),
}
